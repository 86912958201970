<template>
	<div class="sign-up-form">
		<div v-if="!registered">
			<div class="sign-up-form__header">
				<v-btn class="sign-up-form__header-button" icon color="#575E60" @click="frompage ? $router.back() : $router.push({ name: 'Home' })">
					<v-icon class="sign-up-form__header-button-icon">far fa-arrow-left</v-icon>
				</v-btn>
				<h2 class="sign-up-form__header-title">{{ $t('login.signupTitle') }}</h2>
				<div></div>
			</div>
			<form @submit.prevent="doSignUp">
				<div class="sign-up-form__inputs">
					<v-text-field
						class="sign-up-form__input"
						:label="$t('login.name')"
						v-model="name"
						type="text"
						:color="primaryColor"
						required
					></v-text-field>
					<v-text-field
						class="sign-up-form__input"
						:label="$t('login.email')"
						v-model="email"
						type="email"
						:color="primaryColor"
						required
					></v-text-field>
					<v-text-field
						class="sign-up-form__input"
						:label="$t('login.password')"
						v-model="password"
						:type="show ? 'text' : 'password'"
						:color="primaryColor"
						:append-icon="show ? 'visibility' : 'visibility_off'"
						@click:append="show = !show"
						required
					></v-text-field>
					<v-checkbox class="sign-up-form__input" required v-model="checkbox" :color="primaryColor">
						<template v-slot:label>
							<div>
								{{ $t('login.checkbox1') }}
								<v-tooltip disabled>
									<template v-slot:activator="{ on }">
										<a class="sign-up-form__link" href="" @click.stop v-on="on" @click="openDialog($event)" :style="colorStyle">
											{{ $t('login.checkbox2') }}
										</a>
									</template>
								</v-tooltip>
								{{ $t('login.checkbox3') }}
								<v-tooltip disabled>
									<template v-slot:activator="{ on }">
										<a class="sign-up-form__link" href="" @click.stop v-on="on" @click="openDialog($event)" :style="colorStyle">
											{{ $t('login.checkbox4') }}
										</a>
									</template>
								</v-tooltip>
								{{ $t('login.checkbox5') }}
							</div>
						</template>
					</v-checkbox>
				</div>
				<div class="sign-up-form__bottom">
					<v-btn class="sign-up-form__button" color="#2498ff" type="submit" depressed>
						{{ $t('login.submit') }}
					</v-btn>
					<div style="display: flex; justify-content: center">
						<span style="color: #575e60; font-size: 13px; padding-right: 0.5rem">{{ $t('login.have-account') }}</span>
						<router-link :to="{ name: 'Login' }" class="sign-up-form__link" style="font-size: 13px">{{
							$t('login.loginTitle')
						}}</router-link>
					</div>
				</div>
			</form>
			<v-row class="login-form__modal" justify="center">
				<v-dialog v-model="showPolicyModal" v-if="showPolicyModal" scrollable max-width="50%">
					<v-card>
						<v-card-title class="text-h5">
							{{ $t('login.policyModalTitle') }}
						</v-card-title>
						<v-card-text style="padding: 0 2rem"><LegaladviceComponent /></v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="showPolicyModal = false" outlined> {{ $t('login.policyModalButton') }} </v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
		</div>
		<div v-else class="sign-up-form__registered">
			<h3 class="sign-up-form__registered-title">{{ $t('success.succes_signup-title') }}</h3>
			<span class="sign-up-form__registered-subtitle"> {{ $t('success.succes_signup-subtitle') }} </span>
		</div>
	</div>
</template>

<script>
import LegaladviceComponent from '@/components/legaladvice/LegalAdviceComponent.vue';
import { singUp } from '@/api/sessions.js';

export default {
	name: 'SignUpForm',
	props: {
		primaryColor: {
			type: String,
			default: '#d4af37'
		},
		frompage: Boolean
	},
	components: {
		LegaladviceComponent
	},
	data() {
		return {
			show: false,
			email: null,
			password: null,
			name: null,
			checkbox: false,
			showPolicyModal: false,
			colorStyle: {
				'--primaryColor': this.primaryColor
			},
			registered: false
		};
	},

	methods: {
		openDialog(e) {
			e.preventDefault();
			this.showPolicyModal = true;
		},
		async doSignUp() {
			var body = {
				email: this.email,
				password: this.password,
				name: this.name,
				sendEmail: true
			};
			const data = await singUp(this.$store.state.eventInfo.evgrurlname, body);
			data.responseType === 'success'
				? (this.registered = true) //this.$puiNotify.success(this.$t('success.succes_signup'), this.$t('success.success'))
				: this.$puiNotify.error(data.data.message, this.$t('error.error'));
		}
	}
};
</script>

<style lang="scss" scoped>
.sign-up-form {
	background: #fff;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	padding: 2rem;
}

.sign-up-form__header {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&-button {
		position: absolute;
		left: 0;
		width: 1rem;
		height: 1rem;

		&-icon {
			font-size: 1rem !important;
		}
	}

	&-title {
		font-family: Cabin;
		font-weight: bold;
		font-size: 1.625rem;
		color: #293133;
	}
}

.sign-up-form__inputs {
	padding-top: 1rem;
}

.sign-up-form__input {
	width: 20.5rem;
}

.sign-up-form__link {
	color: #304ea2;
}

.sign-up-form__bottom {
	display: flex;
	flex-direction: column;
}

.sign-up-form__bottom-text {
	width: 16rem;
	padding: 0.25rem 0 0.5rem;
}

.sign-up-form__button {
	color: #fff;
	margin: 1.5rem 0;
}

.sign-up-form__registered {
	height: 25rem;
	width: 20.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&-title {
		font-family: Cabin;
		font-weight: bold;
		font-size: 26px;
		color: #293133;
		padding-bottom: 2rem;
	}

	&-subtitle {
		font-size: 20px;
		color: #575e60;
		text-align: center;
	}
}
</style>
